import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby'

import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Error from "../pages/404"
import Banner from '../components/Home/Banner/BranchBanner';
import BranchDetails from "../components/PropertyDetails/BranchDetails/BranchDetails"
import About from "../components/Home/About/BranchAbout"
import BranchIntro from "../components/Home/About/BranchIntro"
import Help from "../components/Home/BookValuation/BranchBookValuation"
import HelpFooter from "../components/Home/BookValuation/BranchBookValuationFooter"
import GetKnow from "../components/Home/GetKnow/BranchGetKnow"
import CountBlock from '../components/Home/CountBlock/OfficeDetailsCountBlock';
import CountBlockLeftContent from '../components/Home/CountBlock/CountBlockLeftContent';
import NewsInsights from '../components/Home/NewsInsights/BranchNewsInsights';
import Properties from "../components/PropertyDetails/Property/Property"
import SuccessStory from "../components/SuccessStories/SuccessStories"
import AreaGuideModule from "../components/AreaGuides/BranchDetailsAreaGuide"
import BranchDetailStructuredData from "../components/Seo/BranchDetailStructuredData"
function BranchDetailsTemplate(props) {

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const GQLPage = props.data?.branches;
    let image_url = null;
    if(GQLPage.image){
     image_url = GQLPage.image[0]?.url;
    }
    return (
        <React.Fragment>
            <div className="homepage branch-details-template">
                <Header />
                <SEO title={GQLPage.branch_name !== "Newport" ? `Estate Agents in ${GQLPage.branch_name} | Pittis` : `Newport Estate Agents | Newport Letting Agents | Pittis`} description={GQLPage.branch_name !== "Newport" ? `Our Estate agents in ${GQLPage.branch_name.replace(" Lettings Agents", "").replace("Fine & Country ", "")} offer the best property advice on Selling, Buying, Letting and Renting your property. Contact us to get assistance.` : `Our estate agents in Newport offer the best property advice on selling, buying, letting and renting your property. Contact Pittis Newport estate agents today.`} image={GQLPage.Banner_Image ? GQLPage.Banner_Image.url : GQLPage.image ? GQLPage.image[0].url : ''} />
                <BranchDetailStructuredData branch={GQLPage} />
                {GQLPage.image &&
                    <Banner {...GQLPage} type="branch" />
                }
                <BranchDetails {...GQLPage} />
                {GQLPage.description &&
                    <BranchIntro  
                    Intro_Title={`About our branch`}
                     Intro_Heading={`Wards in ${GQLPage.branch_name}`} 
                     Intro_Content={GQLPage.description}
                    //  Intro_CTA_Label='Contact Us'
                    //  Intro_CTA_Link='/get-in-touch/'
                     />
                }
                {/* <About Intro_Title={`Moving you forwards`} /> */}
                {GQLPage.success_stories?.success_flyer?.length > 0 &&
                    <SuccessStory {...GQLPage} />
                }

                <Help />

                <NewsInsights Latest_News_Heading={`News from ${GQLPage.branch_name}`} branch_name={GQLPage.branch_name} /> 
                
                {GQLPage.description &&
                     <About Intro_Title={`About ${GQLPage.branch_name.replace(" Lettings Agents", "").replace("Fine & Country ", "")} ${GQLPage.slug != "medway-lettings" ? "Estate Agents" : ""}`} Intro_Heading='' Intro_Content={GQLPage.description} Intro_CTA_Label='' Intro_CTA_Link='' Image={image_url} />
                }

                <GetKnow />
                <CountBlock statsdataall={GQLPage} />
                <AreaGuideModule name={GQLPage.branch_name.replace(" Lettings Agents", "").replace("Fine & Country ", "")} />
                <Properties Name={GQLPage.branch_name.replace(" Lettings Agents", "").replace("Fine & Country ", "")} area={GQLPage.branch_name} />
                <HelpFooter />
                <Footer search="area_office" url={GQLPage.slug} area={GQLPage.branch_name} />

            </div>
        </React.Fragment>
    )
}

export default BranchDetailsTemplate;


export const pageQuery = graphql`
  query ($slug: String) {
    branches(_id: {eq:$slug}) {
        branch_name
        Banner_Image {
          url
        }
        address {
          street
          town
          county
          postcode
          latitude
          longitude
        }
        branch_id
        brand_id
        description
        image {
          srcUrl
          url
          etag
          createdAt
          title
        }
        imagetransforms {
          image_Transforms
        }
        lettings_dept {
          intro
          phone
          email          
            manager {
                image
                job_title
                name
            }
        }
        sales_dept {
          intro
          phone
          email          
            manager {
                image
                job_title
                name
            }
        }
        slug
        _id
        extra {
          branch_name
          visible_on_map
          latitude
          longitude
          branch_id
          description
          opening_hours_website {
            timing
            week_day
          }
        }
        success_stories {
          success_flyer {
            srcUrl
            free_text_1
            free_text_2
            free_text_3
            location
            _created
            propref
          }
        }
        viewings_arranged
        valuations
        gross_value_sold
        mortgages_arranged_value
        total_applicants
        cash_buyers
        nothing_to_sell
        first_time_buyers
        under_offer
        own_to_sell
        over_60s
        }
  }
`